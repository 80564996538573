import { createSelector } from 'reselect';
import constants from 'appConstants';

const getIsLoggedIn = state => state.app.isLoggedIn;

export const getIsSuperAdmin = state =>
    state.app?.permission?.apps &&
    Object.entries(state.app?.permission?.apps)?.some(([key, value]) =>
        value ? [constants.ROLES.ADMIN, constants.ROLES.TECHNICIAN].includes(key) : false
    );
export const getRoles = () => constants.ROLES;
export const getCurrentCompanyId = state => state.app.user.companyId;

export const getIsLoggedInAsSuperAdmin = createSelector(
    getIsLoggedIn,
    getIsSuperAdmin,
    (isLoggedIn, isSuperAdmin) => !!isLoggedIn && !!isSuperAdmin
);
