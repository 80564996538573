import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import PropTypes from 'prop-types';

const CustomScrollbars = ({ style, children, onScroll }) => (
    <Scrollbars
        style={{ ...style, overflow: 'hidden' }}
        renderThumbVertical={props => <div {...props} className="thumb-vertical" />}
        thumbSize={60}
        onScroll={onScroll}
    >
        {children}
    </Scrollbars>
);

CustomScrollbars.propTypes = {
    style: PropTypes.shape(),
    children: PropTypes.node.isRequired,
    onScroll: PropTypes.func,
};

export default CustomScrollbars;
